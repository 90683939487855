// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import map from 'lodash/map'
import isArray from 'lodash/isArray'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Timeline from 'antd/lib/timeline'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/timeline/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import K12SchoolPageWrapper from '../components/wrappers/K12SchoolPageWrapper'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

import timeline from '../data/timeline.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Item } = Timeline
const { Fragment } = React

const pageData = {
  pageTitle: 'K12 School Timeline | Learning Wings Education System',
  nakedPageSlug: 'about',
  pageAbstract:
    'Learning Wings Education Systems has K1-12 and Early Education schools spread over North India. At our schools the goal is to nurture the dreams of tender hearts. Every child is equipped with the confidence to pursue the dreams and realize them. In this world of turmoil and unrest they learn to pave their own path to success.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <K12SchoolPageWrapper className="page" seoData={seoData} {...props}>
    <h1 class="shadow" data-shadow="Brief Timeline">
      Brief Timeline
    </h1>
    <div style={{ maxWidth: '50rem' }}>
      <p>
        Learning Wings Education Systems was born in the summer of 2004, as a
        mission of a visionary entrepreneur, Mr. Ajay Bhatia. To join him in
        this endeavour were Dr. Brijesh Kumar, Ms. Deepa Dogra, Mr. Deepak
        Bhatia, all committed to a single pointed vision - to provide quality
        education to all strata of society at most reasonable and affordable
        cost.
      </p>
      <Timeline>
        {map(timeline, timelineItem => {
          const { text, time } = timelineItem
          return (
            <Item>
              <div>
                <strong>{time}</strong>
                <br />
                {isArray(text) ? (
                  <ul style={{ fontSize: 'unset' }}>
                    {map(text, textItem => (
                      <li style={{ fontSize: 'unset' }}>{textItem}</li>
                    ))}
                  </ul>
                ) : (
                  <Fragment>{text}</Fragment>
                )}
              </div>
            </Item>
          )
        })}
      </Timeline>
    </div>
  </K12SchoolPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
